import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { IonSlides } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute } from '@angular/router';
import { RetailerProductModel } from 'src/app/models/retailerproduct.model';
import { RetailerService } from 'src/app/services/retailer.service';
import { RetailerModel } from 'src/app/models/retailer.model';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss'],
})
export class GalleryRetailerPage implements OnInit {


  images:RetailerProductModel[] = [];
  image360 = [];
  video = [];
  type = 'foto';
  iframeData: any = [];
  imageData360: any = [];
  slug:any = ''
  link360:any = ''
  // type = 'foto';

  reachStart = true;
  reachEnd = false;
  reachStart2 = true;
  reachEnd2 = false;
  reachStart3 = true;
  reachEnd3 = false;
  reachStart4 = true;
  reachEnd4 = false;
  isShowVideoContainer = false;
    isVideo:boolean = false;
  isYoutube:boolean = false;
  projectDetail:any = [];
  @ViewChild('slides') slides: IonSlides;
  @ViewChild('slides2') slides2: IonSlides;
  @ViewChild('slides3') slides3: IonSlides;
  @ViewChild('slides4') slides4: IonSlides;

  slideOpts = {
    spaceBetween: 0,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  slideOpts4 = {
    spaceBetween: 10,
    slidesPerView: 5,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  slideOpts3 = {
    spaceBetween: 12,
    slidesPerView: 4,
    // allowTouchMove: false,      
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1500: {
        slidesPerView: 4,
        spaceBetween: 14,
      },
      1000: {
        slidesPerView: 4,
        spaceBetween: 14,
      },
      576: {
        slidesPerView: 4,
        spaceBetween:12,
      }
    }    
  };

    slideOpts2 = {
    initialSlide: 0,
    speed: 400
  };

  retailerDetail:RetailerModel = new RetailerModel().deserialize("{}")

  isVideoPlay:boolean = false

  constructor(
    public modalController: ModalController,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private retailerService: RetailerService,
    private navParams: NavParams,
  ) {

  }

  ngOnInit() {

    // if(this.route.snapshot.params['product']){
    
      this.images = this.navParams.data.product
      this.slug = this.navParams.data.slug

    // }

    // console.log(this.images)

  //   const data = JSON.parse(localStorage.getItem('property'));
  //   this.images = data.images;
  //   this.image360 = data.image360;


  //   var cleanArray = this.image360.filter(element => element != '');
  //   this.image360 = cleanArray;
    this.video = this.navParams.data.video;
    if(this.navParams.data.link360){
      this.link360 = this.sanitizer.bypassSecurityTrustResourceUrl(this.navParams.data.link360);
    }

    this.retailerDetail = this.navParams.data.retailer
    
  //   this.projectDetail = data.result;

  //   this.generalService.sendAnalytic('view','page-kpr-detail-gallery-'+this.projectDetail.kpr_house_name).subscribe(res => {
  //   });

  //   this.addPointVideo()

  //   // this.image360 = 'https://www.youtube.com/watch?v=O2IuJPh6h_A';

  //   if (this.image360.length > 0) {
  //     this.iframeData = [];
  //     this.imageData360 = [];
  //     this.image360.map( (x, index) => {
  //       if (x.type == 1) {
  //         this.iframeData.push(this.sanitizer.bypassSecurityTrustHtml('<iframe style="width:100%;	height: calc(100vh - 60px);  " src="' + x.iframe + '" frameborder="0"></iframe>'));
  //       }
  //       else {
  //         this.iframeData.push(this.sanitizer.bypassSecurityTrustHtml('<iframe style="width:100%;	height: calc(100vh - 60px)" src="' + x.image + '" frameborder="0"></iframe>'));
  //       }
  //     });
  //   }


  //   if (this.images.length > 0) {
  //     this.type = 'foto';
  //     this.slideChange();
  //   }
  //   // else if (this.image360.length > 0) {
  //   //   this.type = 'tour';
  //   // }
  //   else if (this.video.length > 0) {
  //     this.type = 'video';
  //   }

  // //  if(this.slides){

  // //  }

  }

  getRetailerProduct(id){

    this.loadingCtrl.create({ keyboardClose: true })
    .then(loading => {
      
      loading.present();

      this.retailerService.getRetailerProductList(id).subscribe(

        res => {
          
          if(res.ok == 1){

            this.images = res.data.result.map((medialist:RetailerProductModel) => new RetailerProductModel().deserialize(medialist))

          }

        }

      )

      loading.dismiss();
        
    });

  }

    ionViewDidEnter(){


      if (this.slides) {
        this.slides.update();
      }
       if (this.slides3) {
        this.slides3.update();
      }

      this.generalService.sendAnalytic('view','page-retailer-detail-'+this.slug+'-gallery').subscribe(res => {
      });

  }



  async closeModal() {
    await this.modalController.dismiss();
  }
  prevSlide() {
    // console.log(this.slides);
    this.slides.slidePrev();
  }
  nextSlide() {
    this.slides.slideNext();
  }
  slideChange() {
    try {
      if (this.slides) {

        this.slides.getActiveIndex().then((index) => {

          this.slides3.slideTo(index)

        })

        let prom1 = this.slides.isBeginning();
        let prom2 = this.slides.isEnd();

        Promise.all([prom1, prom2]).then((data) => {
          data[0] ? this.reachStart = true : this.reachStart = false;
          data[1] ? this.reachEnd = true : this.reachEnd = false;
        });
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  prevSlide2() {
    this.slides2.slidePrev();
  }
  nextSlide2() {
    this.slides2.slideNext();
  }
  slideChange2() {

    let prom1 = this.slides2.isBeginning();
    let prom2 = this.slides2.isEnd();

    Promise.all([prom1, prom2]).then((data) => {
      data[0] ? this.reachStart2 = true : this.reachStart2 = false;
      data[1] ? this.reachEnd2 = true : this.reachEnd2 = false;
    });
  }

  prevSlide3() {
    this.slides3.slidePrev();
  }
  nextSlide3() {
    this.slides3.slideNext();
  }
  slideChange3() {
    let prom1 = this.slides3.isBeginning();
    let prom2 = this.slides3.isEnd();

    Promise.all([prom1, prom2]).then((data) => {
      data[0] ? this.reachStart3 = true : this.reachStart3 = false;
      data[1] ? this.reachEnd3 = true : this.reachEnd3 = false;
    });
  }

  prevSlide4() {
    this.slides4.slidePrev();
  }
  nextSlide4() {
    this.slides4.slideNext();
  }
  slideChange4() {
    try {
      if (this.slides4) {
        let prom1 = this.slides4.isBeginning();
        let prom2 = this.slides4.isEnd();

        Promise.all([prom1, prom2]).then((data) => {
          data[0] ? this.reachStart4 = true : this.reachStart4 = false;
          data[1] ? this.reachEnd4 = true : this.reachEnd4 = false;
        });
      }
    }
    catch (e) {
      // console.log(e);
    }

  }

    segmentChanged(ev: any) {
    // console.log(ev);
    // console.log(this.type);
    // console.log('Segment changed', ev);
  }
  showMedia(flag){
    this.slides.slideTo(flag);
  }
  showMedia2(flag){
    this.slides2.slideTo(flag);
  }
  showMedia4(flag){
    this.slides4.slideTo(flag);
  }

  addPointVideo(){

    this.eventService.memberAddActivity(environment.memberActivity.LihatVideoBrand, this.retailerDetail.getID(), this.retailerDetail.getName()).subscribe(res => {

      // console.log("Result Point : " + JSON.stringify(res))

    })

  }

  addPoint360(){

    this.eventService.memberAddActivity(environment.memberActivity.Lihat360Brand, this.retailerDetail.getID(), this.retailerDetail.getName()).subscribe(res => {

      // console.log("Result Point : " + JSON.stringify(res))

    })

  }

  analyticClickAction(tag){

    let tmp = 'retailer-detail-'+this.slug+'-gallery-'+tag

    this.generalService.sendAnalytic('click', tmp).subscribe(res => {
    });
  }

  showLoading(){
    this.loadingCtrl.create({ keyboardClose: true })
    .then(loading => {
        loading.present();
    });
  }
  hideLoading(){
    this.loadingCtrl.dismiss();
    //alert(1);
  }

  btnVideoMobile(){

    console.log("called")

    let video:HTMLVideoElement = <HTMLVideoElement>(document.getElementById('_videogallery'))
    if(video){
      video.play()
    }
    

  }

  videopause(){
    this.isVideoPlay = false
  }

  videoplay(){
    this.isVideoPlay = true
  }

}
