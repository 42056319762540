import { BrosurModel } from "./brosur.model";
import { Deserializable } from "./deserializable.model";

export class RetailerModel implements Deserializable{

	private id:string
    private brand_logo_full:string
    private brand_name:string
	private brand_short_description_id:string
	private brand_description_id:string
	private office_name:string
	private brand_slug:string
	private brosur:BrosurModel[]
	private brandProductImageUrl:string = ""
	private brandProductImageUrl2:string = ""
	private brandUrl:string = ""
	private link_360:any
	private brand_background_full:string
	private brand_background_mobile_full:string

	deserialize(input: any,type:string = "detail") {
		
		
		if(type == "detail"){

			if(input == "{}"){
				Object.assign(this, input);
				this.setBrosur([])
			}else{
				Object.assign(this, input.result[0]);
		
				this.setBrosur(input.brochure)

				if(input.productCategory.length > 0){
					this.brandProductImageUrl = input.productCategory[0].image_thumb_full
					if(input.productCategory[1]){
						this.brandProductImageUrl2 = input.productCategory[1].image_thumb_full
					}
				}

				if(input.result[0].dataLink){
					if(input.result[0].dataLink.length > 0){
						this.brandUrl = input.result[0].dataLink[0].brand_link
					}
					
				}

			}

			

		}else if(type == "list"){
			Object.assign(this, input);
		}

		return this;
	}

	getID(){
		return this.id
	}

	getSlug(){
		return this.brand_slug
	}

	getImageUrl(){
		return this.brand_logo_full
	}

    getName(){
        return this.brand_name
    }

	getDescription(){
		return this.brand_description_id
	}

	
	getShortDescription(){

		let doc = document.createElement('html');
		doc.innerHTML = this.brand_description_id;
		let div1 = doc.querySelectorAll('p');

		let firstHtml = '';
		div1.forEach((item, index) => {
			if (index < 2) {
				firstHtml += '<p>'+item.innerHTML+'</p>';
			}
		});

		return firstHtml

	}

	getDescriptionParagraft(){

		let doc = document.createElement('html');
		doc.innerHTML = this.brand_description_id;
		let div1 = doc.querySelectorAll('p');

		return div1.length

	}

	getOfficeName(){
		return this.brand_short_description_id
	}

	setBrosur(data){

		this.brosur = data.map((brosurlist:BrosurModel) => new BrosurModel().deserialize(brosurlist))

	}

	getBrosur(){

		return this.brosur

	}

	getBrandProductImage(){
		return this.brandProductImageUrl
	}

	getBrandProductImage2(){
		return this.brandProductImageUrl2
	}

	getBrandLink(){
		return this.brandUrl
	}

	get360Link(){
		return this.link_360
	}

	getBrandBackgroundDesktop(){
		return this.brand_background_full
	}

	getBrandBackgroundMobile(){
		return this.brand_background_mobile_full
	}

}