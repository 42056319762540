import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class RetailerService {

  language;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private languageService: LanguageService
  ) { }


  getRetailerList(page) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/retailer/list-retailer?page=${page}`,
    );
  }

  getRetailerDetail(slug) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/retailer/detail?brand_slug=${slug}`,
    );
  }

  getRetailerProductList(id){

    return this.http.get<any>(
      `${environment.baseUrl}/api/retailer/list-product?id=${id}`,
    );

  }

  getRetailerGallery(id){

    return this.http.get<any>(
      `${environment.baseUrl}/api/retailer/galeri?brand_id=${id}`,
    );

  }

}
