import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _language = new BehaviorSubject<String>('id');

  constructor(
    private http: HttpClient
  ) { }

  languageList() {
    return this.http.get<any>(
      `${environment.baseUrl}/api/language_list`
    );
  }

  setLanguage(lang: string) {
    Plugins.Storage.set({key: 'language', value: lang});
    this._language.next(lang);
  }

  language() {
    return this._language.value;
  }

  getLanguageSetting() {
    return Plugins.Storage.get({key: 'language'});
  }

  loadPageLanguage(page) {
    return this.http.get<any>(
      `assets/language/${this._language.value}/${page}.json`
    );
  }
}
