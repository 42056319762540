import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ModalController, NavController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { LivechatPage } from 'src/app/event/ksm/livechat/livechat.page';

@Component({
  selector: 'app-virtual-pengajuan',
  templateUrl: './virtual-pengajuan.page.html',
  styleUrls: ['./virtual-pengajuan.page.scss'],
})
export class VirtualPengajuanPage implements OnInit {


brand_slug:any='';
user:any;
detail:any;
    isAuthenticated:any;
product_category_slug:any='';
    iframeData:any='';
locationId:any='';
  private liveChatType:any = "0";
  private isLiveChatOpen:boolean = false;
  private livechatOffMessages:any = "";

  typeChat: any ='ksm';
  subdataChat: any;
  checkNotif: any;

  constructor(
    private generalService:GeneralService,
    private route: ActivatedRoute,
    private authService:AuthService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private modalController: ModalController,
    private navParams:NavParams,
    private navCtrl:NavController
  ) { }

  ngOnInit() {
    this.brand_slug = this.navParams.get('brand_slug');//:brandSlug/:productCategorySlug
    this.subdataChat = 'information';
    this.product_category_slug = this.navParams.get('product_category_slug');//this.route.snapshot.params['productCategorySlug'];
      this.locationId =  this.navParams.get('locationId');
//    let parameter = '?price=&brand='+this.brand_slug+'&model='+this.product_category_slug+'#gridCar';
//    let url =environment.KKBUrl+parameter;
//

    this.generalService.getBrandDetail(this.brand_slug).subscribe(res => {
      if (res.ok == 1) {

        this.liveChatType = this.generalService.checkLivechatStatus(res.detail.id)
        this.checkLivechatStatus()

      }
    });

  }

 ionViewWillEnter() {
    this.authCheck();

  }
  getDataEnc(){
       if(this.user){  this.generalService.getDataEncryption(this.user.member_fullname,this.user.member_email,this.user.member_phone,this.product_category_slug,this.brand_slug,this.locationId).subscribe(res => {
          if (res.ok == 1) {
            this.detail = res.data;
            let url =environment.KSMUrl+res.data+'/'+res.iv;
                this.iframeData = this.domSanitizer.bypassSecurityTrustHtml('<iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" style="width:100%;height:100vh" src="'+url+'" frameborder="0"></iframe>');



          }
        });
                    }else{
            this.generalService.getDataEncryption('','','',this.product_category_slug,this.brand_slug,this.locationId).subscribe(res => {
          if (res.ok == 1) {
            this.detail = res.data;
            let url =environment.KSMUrl+res.data+'/'+res.iv;
                this.iframeData = this.domSanitizer.bypassSecurityTrustHtml('<iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" style="width:100%;height:100vh" src="'+url+'" frameborder="0"></iframe>');



          }
        });


                    }

  }
 sanitizeUrl(url){
     return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
 }

  authCheck() {
    this.authService.userIsAuthenticated.pipe(take(1), switchMap(auth => {
      if (!auth) {
        return this.authService.autoLogin();
      } else {
        return of(auth);
      }
    })
    ).subscribe(auth => {
      this.isAuthenticated = auth;
      if (auth) {
        this.authService.user.subscribe(user => {
          this.user = user;
            this.getDataEnc();
        });
      }else{
            this.getDataEnc();
      }

    });
  }
    openMeeting(){
        window.open('','_blank');
    }
  async closeModal() {
    await this.modalController.dismiss();
  }

  checkLivechatStatus(){

    this.generalService.getLiveChatStatus(this.liveChatType).subscribe(
      res => {
        if(res.ok == 1){
          this.isLiveChatOpen = true
        }else{
          this.isLiveChatOpen = false
          this.livechatOffMessages = res.message
        }

      }
    )

  }

  async modalLivechat(){

    if(this.isAuthenticated){

      if(this.isLiveChatOpen){
        const modal = await this.modalController.create({
          component:LivechatPage,
          cssClass: 'fullmodal',
        });
        return await modal.present();
      }else{
        this.generalService.showAlertLivechatOff(this.livechatOffMessages)
      }

    }else{
      this.navCtrl.navigateForward("register")
    }
    
  } 

  checkLiveChatNotif(){
    this.generalService.checkLiveChat(this.typeChat,this.subdataChat,this.user.member_id).pipe(take(1)).subscribe(resData => {
      this.checkNotif = resData.ok;
      // console.log(resData);
      // this.showDetail(this.data[0].faq_id)
    }, err => { });
  }

}
