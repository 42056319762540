import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { SafePipe } from './safe.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InitialPipe } from './pipes/initial.pipe';
import { LivechatPage } from './event/kkb/livechat/livechat.page';
import { LivechatPage  as LPage} from './event/ksm/livechat/livechat.page';
import { VirtualPengajuanPage  as vPage} from './event/ksm/virtual-pengajuan/virtual-pengajuan.page';
import { GalleryPage } from './event/kpr/gallery/gallery.page';
import { SwiperModule } from 'swiper/angular';
import {SearchPopupPage} from "./event/kpr/search-popup/search-popup.page";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { GalleryRetailerPage } from './event/retailer/gallery/gallery.page';
import { GallerySolusiPage } from './event/solusi/gallery/gallery.page';
import { RecaptchaModule } from "ng-recaptcha";
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent, SafePipe, InitialPipe,LivechatPage,LivechatPage,LPage,GalleryPage,SearchPopupPage,vPage, GalleryRetailerPage, GallerySolusiPage],
  entryComponents: [LivechatPage,LivechatPage,LPage,GalleryPage,vPage, GalleryRetailerPage, GallerySolusiPage],
  imports: [    HttpClientModule,FormsModule,
    NgxMaskModule.forRoot(),
             NgMultiSelectDropDownModule.forRoot(),
                IonicStorageModule.forRoot(),    RecaptchaModule,

            BrowserModule, IonicModule.forRoot({animated: false}), AutocompleteLibModule, AppRoutingModule, SwiperModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
