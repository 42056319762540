import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initial'
})
export class InitialPipe implements PipeTransform {

  transform(value: string): any {
    let name = value.split(" ");
    let initial ='';
    if (name.length > 1) {
      initial = name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase();
    } else {
      initial = name[0].charAt(0).toUpperCase();
    }
    return initial;
  }

}
