import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { User } from '../models/user.model';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';
import firebase from 'firebase/app'
import 'firebase/database';
export const myFirebaseRedirect = firebase.initializeApp(environment.firebase, 'redirectFirebase');
export const chatRoomDefault = '/kelolain/multiple_login/';
declare var generateLiveChat: Function;
declare var generateLiveChat2: Function;


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject<User>(null);
  private _fcm = new BehaviorSubject<String>(null);
  private myFirebaseListener;
  language;

  constructor(
    private http: HttpClient,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.language = this.languageService.language();
  }

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }

  get user() {
    var dataUser =  this._user.value;
    if(dataUser){
      localStorage.dataGlobal =  btoa(JSON.stringify(dataUser));
       generateLiveChat();
    }else{
      generateLiveChat2();
    }
    return this._user.asObservable();
  }

  login(email: string, password: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    body.append('email', email);
    body.append('password', password);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/login`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }


  loginVIP(qrcode: string, uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    body.append('qrcode', qrcode);
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/event/scan_invitation_code`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }

  loginGoogle(email: string, hash_code: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    body.append('email', email);
    body.append('hash_code', hash_code);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/googlesignin`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        //tap(this.setUserData.bind(this))
      );
  }

  appleLogin(email: string, fullname: string, apple_id: string, hash_code: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    if (email != '' && email != 'null' && email != null) {
      body.append('member_email', email);
    }
    if (email != '' && email != 'null' && email != null) {
      body.append('member_fullname', fullname);
    }
    body.append('apple_id', apple_id);
    body.append('hash_code', hash_code);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/applesignin`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        //tap(this.setUserData.bind(this))
      );
  }
  getTokenOnly(){
      if(localStorage.dataGlobal){

        try{

          var getData =  JSON.parse(atob(localStorage.dataGlobal));
          return getData._token;

        }catch(e){

        }
        
      }
  }

  setUserData(data: any) {
    const user = new User(
      data.member_id,
      data.member_email,
      data.member_username,
      data.member_fullname,
      data.member_phone,
      data.member_gender,
      data.member_company_name,
      data.member_image,
      data.member_company_position,
      data.member_location,
      data.member_province,
      data.member_type,
      data.code,
      data.token,
      data.enc_data,
      data.greetings
    );
    if (data.token2) {
      let redirectData = {
        "member_id": data.member_id,
        "token2": data.token2
      }
      Plugins.Storage.set({ key: 'token2', value: JSON.stringify(redirectData) });
    }
    this._user.next(user);

    const authData = JSON.stringify({
      member_id: data.member_id,
      member_email: data.member_email,
      member_username: data.member_username,
      member_fullname: data.member_fullname,
      member_phone: data.member_phone,
      member_gender: data.member_gender,
      member_company_name: data.member_company_name,
      member_image: data.member_image,
      member_company_position: data.member_company_position,
      member_location:data.member_location,
      member_province:data.member_province,
      member_type:data.member_type,
      code:data.code,
      token: data.token,
      enc_data: data.enc_data,
      greetings: data.greetings
    });
    Plugins.Storage.set({ key: 'authData', value: btoa(authData) });

    Plugins.Storage.get({ key: 'token2' }).then((val) => {
      this.callForceRedirectFunction(val);
    });
  }
  callForceRedirectFunction(dataToken) {
//    var tokenData = JSON.parse(dataToken.value);
//    if (tokenData) {
//      let myLoginToken = tokenData.token2;
//      let getThisIonic = this;
//      this.myFirebaseListener = myFirebaseRedirect.database().ref(chatRoomDefault + tokenData.member_id);
//      this.myFirebaseListener.on('value', (snapshot) => {
//        let myValue = snapshot.val();
//          if(myValue){
//            if (myLoginToken != myValue.token) {
//              ///   console.log('Token tidak sama');
//              if (this.myFirebaseListener) {
//                this.myFirebaseListener.off();
//              }
//              this._user.next(null);
//              Plugins.Storage.remove({ key: 'authData' });
//              Plugins.Storage.remove({ key: 'token2' });
//              this.router.navigateByUrl('/dual-login');
//            } else {
//              //  console.log('Token Sama');
//            }
//          }
//      });
//    }
  }
  autoLogin() {
    Plugins.Storage.get({ key: 'token2' }).then((val) => {
      this.callForceRedirectFunction(val);
    });
    return from(Plugins.Storage.get({ key: 'authData' })).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(atob(storedData.value)) as {
          member_id: string;
          member_email: string;
          member_username: string;
          member_fullname: string;
          member_phone: string;
          member_gender: string;
          member_company_name: string;
          member_image: string;
          member_company_position: string;
            member_location: string;
            member_province:string;
            member_type:string;
            code:string;
          token: string;
          enc_data:string;
          greetings:string
        };
        const user = new User(
          parsedData.member_id,
          parsedData.member_email,
          parsedData.member_username,
          parsedData.member_fullname,
          parsedData.member_phone,
          parsedData.member_gender,
          parsedData.member_company_name,
          parsedData.member_image,
          parsedData.member_company_position,
          parsedData.member_location,
          parsedData.member_province,
          parsedData.member_type,
          parsedData.code,
          parsedData.token,
          parsedData.enc_data,
          parsedData.greetings
        );
        return user;
      }),
      tap(user => {
        if (user) {
          generateLiveChat();
          this._user.next(user);
        }else{
          generateLiveChat2();
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  logout(type = 0) {
    if (this.myFirebaseListener) {
      this.myFirebaseListener.off();
    }
    this._user.next(null);
    Plugins.Storage.remove({ key: 'authData' });
    Plugins.Storage.remove({ key: 'token2' });
    localStorage.removeItem("dataGlobal");
    if (type = 0) {
      this.router.navigateByUrl('/');
      //window.location.reload()
    } else {
      window.top.location.href = environment.baseUrlShare;
    }
  }

  logout_V2() {
    //    return this.token.pipe(
    //      take(1),
    //      switchMap(token => {
    //        const httpOptions = {
    //          headers: new HttpHeaders({
    //            'Authorization': `Bearer ${token}`
    //          })
    //        };
    //        return this.http.get<any>(
    //          `${environment.baseUrl}/api/logout`, httpOptions
    //        );
    //      })
    //    );
  }

  loginWithCode(code,utm_source,utm_campaign,utm_leads,capchatoken){
    let body = new FormData();
    body.append('code', code);
    body.append('utm_source', utm_source);
    body.append('utm_campaign', utm_campaign);
    body.append('utm_leads', utm_leads);
    body.append('token', capchatoken);
    return this.http.post<any>(
      `${environment.baseUrl}/api/login_with_code`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }
    registerLogin(email,phone,fullname,location,utm_source,utm_campaign,utm_leads){
    let body = new FormData();
    body.append('email', email);
    body.append('phone', phone);
    body.append('fullname', fullname);
    body.append('location', location);
    body.append('utm_source', utm_source);
    body.append('utm_campaign', utm_campaign);
    body.append('utm_leads', utm_leads);
    return this.http.post<any>(
      `${environment.baseUrl}/api/register_login`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }

  get fcm() {
    return this._fcm.asObservable();
  }

  setFcmToken(token) {
    this._fcm.next(token);
  }

  checkCode(code){
    let body = new FormData();
    body.append('code', code);
    return this.http.post<any>(
      `${environment.baseUrl}/api/member/checkcode`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }

}
