import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, LoadingController } from '@ionic/angular';
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';
import { NgForm } from '@angular/forms';
import firebase from 'firebase/app'
import 'firebase/firestore';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkifyjs/html';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';
import { Plugins, CameraResultType,CameraSource  } from '@capacitor/core';
const { Camera } = Plugins;
export const myChatFirebase = firebase.initializeApp(environment.firebase,'ChatFirebaseX');
export const chatRoomDefault = 'pr/unictive/informationchat/';
export const pageSize = 2;
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { GeneralService } from 'src/app/services/general.service';
import { NavParams } from '@ionic/angular';
defineCustomElements(window);

@Component({
  selector: 'app-livechat',
  templateUrl: './livechat.page.html',
  styleUrls: ['./livechat.page.scss'],
})
export class LivechatPage implements OnInit {
typeChat:any= 'ksm';
 isAuthenticated: any;
  user : any = [];
  chatting : any = [];
  roomId: any;
  brand_slug:any;
roomData:any;
    lastPage:any;
      disableInfinite = false;
      page = 1;
 product_category_slug:any;
  chatRoom:any;
  @ViewChild('commentEl') comment: ElementRef;
  scrolltop: number = null;
  subDataChat:any='information';
 @ViewChild('myDivChat') private myDivchat: any;

  constructor(
    private actionSheetController: ActionSheetController,
    private authService: AuthService,
      private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private router: Router,
    private accountService: AccountService,
     private alertCtrl: AlertController,
    private languageService: LanguageService,
    public eventService: EventService,
    private generalService:GeneralService,
    public modalController: ModalController,
         private navParams:NavParams,


  )
  {
      this.getProfile();
  }

 ngOnInit() {
    let header = this.route.snapshot.params['header'];
    this.brand_slug = this.navParams.get('brand_slug'); //:brandSlug/:productCategorySlug
    this.product_category_slug = this.route.snapshot.params['productCategorySlug'];

    this.chatRoom = chatRoomDefault+this.typeChat+'/'+this.subDataChat+'/'+this.user.member_id;

    this.eventService.pubHeader({
      header: header,
    });
        this.roomId = '';
  }
     ionViewWillEnter() {
    //this.getProfile();
  //  this.getChatList();

  }
  showAlert(message: string) {
    this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'custom-alert',
      message: message,
      buttons: ['OK']
    })
      .then(alertEl => alertEl.present());
  }


   sendChatImages(type,text){
    var ionicThis = this;

          this.createRoomName();


            var db = myChatFirebase.firestore();
            this.sendFcmNotification(this.roomData);
          var sendChatFirebase =  db.collection(this.roomData);
            sendChatFirebase.add({
               from_user_id: this.user.member_id,
               text: text,
              userName:this.user.member_fullname,
               type:type,
               created_at: firebase.firestore.FieldValue.serverTimestamp()
              }).then(function(docRef) {
                ionicThis.scrollToBottomOnInit();
                   // console.log("Document written with ID: ", docRef.id);
                })
                .catch(function(error) {
                    console.error("Error adding document: ", error);
                });




  }


  sendChat(form: NgForm){
    var ionicThis = this;
     let text = form.value.text;
      if(text == '' || text == null){
           this.showAlert("Pesan tidak boleh kosong");
      }else{
          this.createRoomName();
           form.reset();

            var db = myChatFirebase.firestore();
            this.sendFcmNotification(this.roomData);
          var sendChatFirebase =  db.collection(this.roomData);
         
            sendChatFirebase.add({
               from_user_id: this.user.member_id,
               text: text,
              userName:this.user.member_fullname,
               type:'text',
               created_at: firebase.firestore.FieldValue.serverTimestamp()
              }).then(function(docRef) {
                   form.reset();
                ionicThis.scrollToBottomOnInit();
                   // console.log("Document written with ID: ", docRef.id);
                })
                .catch(function(error) {
                    console.error("Error adding document: ", error);
                });


      }

  }
  sendFcmNotification(roomCode){
    this.generalService.sendFcmNotification(roomCode).subscribe((result:any) => {
        // console.log(result);
    });
  }

   getChatList(){
    let loadData = 1;
    var ionicThis = this;
     var db = myChatFirebase.firestore();
      var myRoomListForChat = this.roomData;
      db.collection(myRoomListForChat).orderBy('created_at','desc')
        .onSnapshot(function(querySnapshot) {
            ionicThis.chatting = [];
            querySnapshot.forEach(function(doc) {
                let dataChx = doc.data();
                dataChx.initialAvatar  = ionicThis.initialName(dataChx.userName);
                ionicThis.chatting.unshift(dataChx);
                ionicThis.scrollToBottomOnInit();

            });
            if(loadData == 1){
                ionicThis.chatting.push({
               from_user_id: '0',
               text: environment.defaultChatText,
              userName:'System Admin',
               type:'text',
                initialAvatar:'SA',
               created_at: '',
              });
                loadData = 2;
            }
        });

   }
    getInputFiles(){
      document.getElementById('file-input').click();
  }
       loadFileFromDevice(event) {

         this.loadingCtrl.create({ keyboardClose: true })
          .then(loading => {
            loading.present();

                  const file = event.target.files[0];
                  if(file){
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        var  blob = file;
                          this.generalService.chatAttachFiles(blob).subscribe((newImage:any) => {
                              if(newImage.ok == 1){
                                  this.sendChatImages(newImage.type,newImage.url);
                              }else{
                                this.showAlert(newImage.message);
                              }
                              loading.dismiss();
                          });


                    };

                    reader.onerror = (error) => {

                                  loading.dismiss();

                    };
                  }else{
                         loading.dismiss();
                  }




          });
    };

    async addActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [ {
        text: 'Photo gallery',
        handler: () => {
          this.getInputFiles();
        }
      }, {
        text: 'Document',
        handler: () => {
            this.getInputFiles();
//          console.log('Document');
        }
      },{
        text: 'CANCEL',
        role: 'destructive',
        handler: () => {
          // console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }
      async takePicture() {
         let image = await Camera.getPhoto({
          quality: 60,
          allowEditing: true,
          resultType: CameraResultType.Base64,
              source: CameraSource.Camera,
        });
        let blobData = this.b64toBlob(image.base64String, `image/${image.format}`);
        let myImageFormat = image.format;
        this.generalService.chatAttachFiles(blobData).subscribe((newImage: any) => {

            if(newImage.ok == 1){
                this.sendChatImages(newImage.type,newImage.url);
            }
        });


    }


     b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
   createRoomName(){
        myChatFirebase.database().ref(this.roomData).update({
               userRoomName:this.user.member_fullname,
               isUserOpen:1,
               isAdminOpen:0,
               userId:this.user.member_id,
               memberCode:this.user.code,
               urlLink:this.router.url,
               updatedAt: firebase.database.ServerValue.TIMESTAMP
              }, function(error) {

           });
  }
   getProfile(){
        this.loadingCtrl.create({ keyboardClose: true })
          .then(loading => {
            loading.present();
              this.authService.userIsAuthenticated.pipe(take(1), switchMap(auth => {
                  if (!auth) {
                    return this.authService.autoLogin();
                  } else {
                    return of(auth);
                  }
                             loading.dismiss();
                })
                ).subscribe(auth => {
                  this.isAuthenticated = auth;
                  if (auth) {
                    this.authService.user.subscribe(user => {
                      this.user = user;
                        this.roomData = chatRoomDefault+'room/'+this.typeChat+'/'+this.subDataChat+'/'+this.user.member_id;
                        this.getChatList();
                        this.postLiveChatNotif();
                    });
                  }
                             loading.dismiss();
                });
          });
   }
   initialName(wxName:any){
     let member_name = wxName.split(" ");
      if(member_name.length > 1) {
        return member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();
      } else {
        return member_name[0].charAt(0).toUpperCase();
      }

   }
     addUrl(inputText:any) {
      return inputText ? linkifyHtml(inputText, {target: '_blank'}) : inputText;
       //return inputText;
  }
    openIframe(url){
        window.open(url,'_blank');
    }


    doEnter(e,f){
     if(e.which == 13 && !e.shiftKey) {
      this.sendChat(f);
    }
  }
  scrollToBottomOnInit() {
    setTimeout(() => {
      this.scrolltop = this.comment.nativeElement.scrollHeight;
     }, 1);
  }
    async closeModal() {
    await this.modalController.dismiss();
  }  
   getNameFiles(fullPath:any){
    var filename = fullPath.replace(/^.*[\\\/]/, '');
    return filename;
  }
  postLiveChatNotif(){
    this.generalService.postLiveChat(this.typeChat,this.subDataChat,this.user.member_id).pipe(take(1)).subscribe(resData => {
      // this.showDetail(this.data[0].faq_id)
    }, err => { });
  }

}
