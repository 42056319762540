import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
import { NavController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class CountdownGuard implements CanActivate {
    constructor(
    private generalService: GeneralService,
    private router: Router,
    private navCtrl:NavController
  ) {}


  canActivate() {
     let now = new Date();
        let openDate = new Date(environment.dateGuard);
        if(localStorage.byPass != '1'){
          if (now.getTime() >= openDate.getTime()) {
              return true;
          } else {
              this.router.navigateByUrl('/countdown');
              return false;
          }
        }else{
          return true;
        }

  }
}
