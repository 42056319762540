// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  lang: 'en',
  captchaKey:'6LcjMk8bAAAAADBbJxOOc-NyCWPloqN06mfV9MBB',
  event_id: '28',
  urlAnalytic: 'https://analytic.unictdev.com/api/make-list',
  baseUrl: 'https://staging-kpr2021.unictdev.com/v1/lumen-api/public',
  baseUrlShare: 'https://staging-kpr2021.unictdev.com/',
  KKBUrl: 'https://kreditkerenbanget.com/car-list/',
  liveChatIframe:'https://halo-wchat.d-appspecto.com/buzz-expo/#/id/chat?data=',
  KSMUrl: 'https://virtualexpo.bcamf.co.id/expo-bca/form/',
  KKBUrlPromo: 'https://kreditkerenbanget.com/package-list/',
  API2Url: 'https://staging-kpr2021.unictdev.com/v1/api',
  API3Url: 'https://beta.unictdev.com/unictive-location/api',
  KPRType:'2',
  KSMType:'3',
  KKBType:'4',
  UnitBisnisType:'5',
  RetailerType:'6',
  provinceDefault:'19',
  defaultChatText:'Selamat datang di Live Chat BCA Expoversary Online 2021.<br>Kami siap melayani setiap: Hari Senin - Minggu Pukul 08.30 - 17.30 WIB<br>Dengan senang hati kami akan memberikan informasi yang Bapak/Ibu butuhkan.',
  defaultChatText2:'Selamat datang di Live Chat BCA Expoversary Online 2021.<br>Kami siap melayani setiap: Hari Senin - Jumat Pukul 08.30 - 17.30 WIB<br>Dengan senang hati kami akan memberikan informasi yang Bapak/Ibu butuhkan.',
  firebase : {
    apiKey: "AIzaSyCTGxieyktAwQ7Nsin32YvrsydgXT-kbwk",
    authDomain: "newlaunch-ea619.firebaseapp.com",
    databaseURL: "https://bca2021.firebaseio.com",
    projectId: "newlaunch-ea619",
    storageBucket: "newlaunch-ea619.appspot.com",
    messagingSenderId: "513186199428",
    appId: "1:513186199428:web:cfd83635af133381218fd1",
    measurementId: "G-DVYLT114QP"
  },
  zoom : {
    apiKey: "jj_mev97TVSjc98mY7Qohg",
    apiSecret: "JwZmABmAlslp9twCtM4MoRE6HJGUWNDPeiGa"
  },
  zoomweb: {
    apiKey: "jj_mev97TVSjc98mY7Qohg",
    apiSecret: "JwZmABmAlslp9twCtM4MoRE6HJGUWNDPeiGa"
  },
  dateGuard: '2021-02-27T00:00:00',
  memberActivity: {
    scanMember: "1",
    scanExhibitor: "2",
    scanExhibitorPic: "3",
    scanExhibitorBrochure: "4",
    scanSession: "5",
    scanActivities: "6",
    answerSurvey: "10",
    answerQuiz: "11",
    unlockBadges: "12", 
    transactionTicket: "13",
    transactionShop: "14", 
    loginRegister: "15", 
    LihatProfileBrand: "16",
    LihatProfileProductCategory: "17",
    LihatProfileProduct: "18",  
    LihatProfileKPR: "19",
    LihatVideoBrand: "20",
    LihatVideoProductCategory: "21",
    LihatVideoProduct: "22",
    LihatVideoKPRProject: "23",
    DownloadBrochureBrand: "24",
    DownloadBrochureProductCategory: "25",
    DownloadBrochureProduct: "26",
    DownloadBrochureKPR: "27",
    ApplyProductBrand: "28",  
    ApplyProductProductCategory: "29",
    ApplyProductProduct: "30",
    ApplyProductKPR: "31",
    SearchProductKPR: "32",
    LihatProfileKPRDev: "33",
    LihatGalleryBrand: "34",
    LihatGalleryProduct: "35",
    DownloadPricelistKPR: "36",
    Lihat360Brand: "37",
    LihatGalleryKPRProject: "38",
    Lihat360KPRProject: "39",
  },
  surveyID:'14',
  urlAjukanRenovasi:'https://webform.bca.co.id/KPR/?prev=/KPR/Screening-eForm-KPR?sourcecode=F76C1317-E140-4E5C-AB6F-DF300751AA78',
  provinsiAceh:'20'

};
