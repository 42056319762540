import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LanguageService } from './language.service';
import {map} from "rxjs/operators";
import { AlertController } from '@ionic/angular';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { AuthService } from './auth.service';
const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleTagManager({
      containerId: 'GTM-KP2V2HC'
    })
  ]
})

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `Tpk8lK1otI4KYOQLuXFLnObNPx607ldc`
    })
  };

  language;


  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private alertCtrl: AlertController,
    private authService:AuthService,
  ) {
    this.language = this.languageService.language();
  }

  sendTrackingGA(trackingEvent,data){
   return  analytics.track(trackingEvent,data);
  }

  getProvince() {
    return this.http.get<any>(
      `${environment.baseUrl}/api/province`,this.httpOptions
    );
  }
  getKota(kotaId){
    return this.http.get<any>(
      `${environment.baseUrl}/api/province/${kotaId}`,this.httpOptions
    );
  }
  getKprBunga(){
    return this.http.get<any>(
      `${environment.baseUrl}/api/kpr/bunga`,this.httpOptions
    );
  }
  getKotaWithProvince(kotaId){
    return this.http.get<any>(
      `${environment.baseUrl}/api/provincekota/${kotaId}`,this.httpOptions
    );
  }
  getBanner(type){
    return this.http.get<any>(
      `${environment.baseUrl}/api/banner/${type}`,this.httpOptions
    );
  }
    chatAttachFiles(image) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
               // 'Authorization': 'Bearer' + authData.token
            })
        };
        const body = new FormData();
        body.append('file',image);
       return this.http.post<any>(
      `${environment.baseUrl}/api/file/upload`,
      body
    );
    }

 getBrandLocation(province_id,kabupaten_id,keyword,brand_type){
    let body = new FormData();

    body.append('keyword',keyword);
     body.append('provinsi_id',province_id);
     body.append('kabupaten_id',kabupaten_id);
     body.append('brand_type',brand_type);
    return this.http.post<any>(
      `${environment.baseUrl}/api/brandlist`,
      body
    );
 }

 getBrandListSolusi(province_id,kabupaten_id,keyword,brand_type){
    let body = new FormData();

    body.append('keyword',keyword);
    body.append('provinsi_id',province_id);
    body.append('kabupaten_id',kabupaten_id);
    body.append('brand_type',brand_type);
    return this.http.post<any>(
      `${environment.baseUrl}/api/solusilist`,
      body
    );
  }

 getDataEncryption(fullname,email,phone,model,brand,locationId){
    let body = new FormData();

    body.append('fullname',fullname);
     body.append('email',email);
     body.append('phone',phone);
       body.append('model',model);
       body.append('brand',brand);
       body.append('location',locationId);
    return this.http.post<any>(
      `${environment.baseUrl}/api/getencryption`,
      body
    );
 }
  sendAnalytic(type,action){
    let body = new FormData();

    body.append('type',type);
    body.append('action',action);

    try{

      body.append('dataUser',atob(localStorage.dataGlobal));

    }catch(e){

      body.append('dataUser',localStorage.dataGlobal);

    }

    return this.http.post<any>(
      `${environment.urlAnalytic}`,
      body
    );
 }



 getOpeningCeremony(){
  return this.http.get<any>(
      `${environment.baseUrl}/api/openingceremony`,this.httpOptions
    );
 }

 getBrandDetail(slug){
  return this.http.get<any>(
      `${environment.baseUrl}/api/branddetail/${slug}`,this.httpOptions
    );
 }

 getBrandDetailSolusi(slug){
  return this.http.get<any>(
      `${environment.baseUrl}/api/solusilist/${slug}`,this.httpOptions
    );
 }

 getBrandDetailLocation(slug,location_id){
  return this.http.get<any>(
      `${environment.baseUrl}/api/branddetail/${slug}/${location_id}`,this.httpOptions
    );
 }

 getBrochureOnly(slug){
  return this.http.get<any>(
      `${environment.baseUrl}/api/brochure/${slug}`,this.httpOptions
    );
 }
 getBrandProductCategory(brand_slug,product_category_slug){
   var token = this.authService.getTokenOnly() ;
   const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
  };
		
  return this.http.get<any>(
      `${environment.baseUrl}/api/brand_product_category/${brand_slug}/${product_category_slug}`,httpOptions
    );
 }

 getKprData(params){
  return this.http.get<any>(
      `${environment.baseUrl}/api/kpr/${params}`,this.httpOptions
    );
 }

 getKprSearchData(params){
  return this.http.get<any>(
      `${environment.baseUrl}/api/kpr/search/${params}`,this.httpOptions
    );
 }

 cekDataSearchKpr(){
  return this.http.get<any>(
      `${environment.baseUrl}/api/kpr-search-new`,this.httpOptions
    );
 }

 getKprSearchDataMin(params){
  return this.http.get<any>(
      `${environment.baseUrl}/api/kpr/search-min/${params}`,this.httpOptions
    );
 }

    getKprDeveloper(params){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/list-developer${params}`,this.httpOptions
        );
    }

    getKprBroker(params){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/list-broker${params}`,this.httpOptions
        );
    }

    getKprDeveloperData(slugs){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/developer/${slugs}`,this.httpOptions
        );
    }

    getKprDeveloperDetailData(slugs){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/developer-detail-project/${slugs}`,this.httpOptions
        );
    }

    getKprBrokerData(slugs){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/broker/${slugs}`,this.httpOptions
        );
    }

    getKprBrokerPropertyData(slugs){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/broker-property/${slugs}`,this.httpOptions
        );
    }

    getKprBrokerDetailData(slugs){
        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/broker-detail-property/${slugs}`,this.httpOptions
        );
    }

    getKprPropertyData(slugs){

        var token = this.authService.getTokenOnly() ;
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
            `${environment.baseUrl}/api/kpr/property/${slugs}`,httpOptions
        );
    }
    sendFcmNotification(code){
      const body = new FormData();
      body.append('code', code);
      return this.http.post<any>(
          `${environment.baseUrl}/api/send-notification`,
          body
      ).pipe(
          map(resData => {
              return resData;
          }),
      );
    }
    checkLiveChat(type,subdata,member_id){
      const body = new FormData();
      body.append('type', type);
      body.append('subdata',subdata);
      body.append('member_id', member_id);
      return this.http.post<any>(
          `${environment.baseUrl}/api/check_master_room`,
          body
      ).pipe(
          map(resData => {
              return resData;
          }),
      );
  }
   postLiveChat(type,subdata,member_id){
      const body = new FormData();
      body.append('type', type);
      body.append('subdata',subdata);
      body.append('member_id', member_id);
      body.append('memberOpen','0');
      body.append('adminOpen','1');
      return this.http.post<any>(
          `${environment.baseUrl}/api/master_room`,
          body
      ).pipe(
          map(resData => {
              return resData;
          }),
      );
  }


    getContactUs(user){

        // let params = '?user_id='+user.id+'&name='+user.name+'&phone='+user.phone+'&email='+user.email+
        //     '&kabupaten_id='+user.city+'&kabupaten_name='+user.city_name+'&ms_kpr_id='+user.ms_kpr_id+
        //     '&developer_name='+user.developer_name+'&ms_broker_id='+user.ms_broker_id+
        //     '&broker_name='+user.broker_name+'&ms_house_id='+user.ms_house_id+'&project_name='+user.project_name+
        //     '&full_path='+user.full_path+'&path='+user.path+'&message='+user.message;
        // return this.http.get<any>(
        //     `${environment.baseUrl}/api/kpr/contact-us`+params
        // );

        const body = new FormData();

        body.append('user_id', user.id);
        body.append('name', user.name);
        body.append('phone', user.phone);
        body.append('email', user.email);
        body.append('kabupaten_id', user.city);
        body.append('kabupaten_name', user.city_name);
        body.append('ms_kpr_id', user.ms_kpr_id);
        body.append('developer_name', user.developer_name);
        body.append('ms_broker_id', user.ms_broker_id);
        body.append('broker_name', user.broker_name);
        body.append('ms_house_id', user.ms_house_id);
        body.append('project_name', user.project_name);
        body.append('message', user.message);
        body.append('source', user.source);
        body.append('full_path', user.full_path);
        body.append('path', user.path);

        return this.http.post<any>(
            `${environment.baseUrl}/api/kpr/contact-us`,
            body
        ).pipe(
            map(resData => {
                return resData;
            }),
        );
    }

    getProductCategoryDetail(slug){
      return this.http.get<any>(`${environment.baseUrl}/api/product_category/${slug}`, this.httpOptions)
    }

    getBrosurByProductID(id){
      return this.http.get<any>(`${environment.baseUrl}/api/brochure_product/${id}`, this.httpOptions)
    }

    getNewsList(page,limit){
      return this.http.get<any>(`${environment.baseUrl}/api/news?page=${page}&limit=`+limit, this.httpOptions)
    }

    getNewsDetail(slug){
      return this.http.get<any>(`${environment.baseUrl}/api/news/${slug}`, this.httpOptions)
    }

    getSetting() {
      return this.http.get<any>(
        `${environment.baseUrl}/api/company_setting`
      );
    }

    getProvinceKotaDetail(province_id, kabupaten_id){
      return this.http.get<any>(
        `${environment.baseUrl}/api/province_detail/${province_id}/${kabupaten_id}`
      );
    }

    getSearchByKeyword(keyword:string){
      return this.http.get<any>(
        `${environment.baseUrl}/api/search_keyword?keyword=${keyword}`
      );
    }

    submitContatcUsSolusi(nama, phone, email, location_id, type, id, token){

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let body = new FormData();

      body.append('name',nama);
      body.append('phone',phone);
      body.append('email',email);
      body.append('location_id',location_id);
      body.append('type',type);
      body.append('id',id);
      return this.http.post<any>(
        `${environment.baseUrl}/api/hubungi_saya`,
        body,
        httpOptions
      );

    }

    sendEmail(email, id, token){

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let body = new FormData();

      body.append('email',email);
      body.append('hubungi_id',id);
      return this.http.post<any>(
        `${environment.baseUrl}/api/contact-us/email-send`,
        body,
        httpOptions
      );

    }

    checkLivechatStatus(brandID){

      let type:string = "0";

      //type 2 itu snen-jumat
      //type 1 itu snen-minggu

      if(brandID == "29"){
        type = "2"
      }else{
        type = "1"
      }

      return type;

    }

    getLiveChatStatus(type:string){
      return this.http.get<any>(
        `${environment.baseUrl}/api/check_livechat?type=${type}`
      );
    }

    showAlertLivechatOff(message) {
      this.alertCtrl.create({
        mode: 'ios',
        cssClass: 'custom-alert2',
        message: message,
        buttons: ['OK']
      })
        .then(alert => {
          alert.present();
        });
    }

    getuserType(user){

      let membertype = ""

      if(user.member_type == "1"){
        membertype = "General"
      }

      if(user.member_type == "2"){
        membertype = "KPR"
      }

      if(user.member_type == "3"){
        membertype = "KSM"
      }

      if(user.member_type == "4"){
        membertype = "KKB"
      }

      if(user.member_type == "5"){
        membertype = "Unit Bisnis"
      }

      return membertype;

    }

    getAdsBrand(slug:string){
      return this.http.get<any>(
        `${environment.baseUrl}/api/getAdsBooth?brand_slug=${slug}`
      );
      
    }

    getAdsProductCategory(brand_slug:string,slug:string){
      return this.http.get<any>(
        `${environment.baseUrl}/api/getAdsBooth?brand_slug=${brand_slug}&product_category_slug=${slug}`
      );
    }

    saveBrosur(brochureID, type){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let body = new FormData();

      body.append('brochure_id',brochureID);
      body.append('type', type)

      return this.http.post<any>(
        `${environment.baseUrl}/api/event/briefcase`,
        body,
        httpOptions
      );

    }

    saveProperty(propertyID){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let body = new FormData();

      body.append('property_id',propertyID);
      body.append('delete',"0");

      return this.http.post<any>(
        `${environment.baseUrl}/api/event/property`,
        body,
        httpOptions
      );

    }

    deleteBrosur(brochureID){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let body = new FormData();

      body.append('brochure_id',brochureID);
      body.append('delete', "1");

      return this.http.post<any>(
        `${environment.baseUrl}/api/event/briefcase`,
        body,
        httpOptions
      );

    }

    deleteProperty(propertyID){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      let body = new FormData();

      body.append('property_id',propertyID);
      body.append('delete', "1");

      return this.http.post<any>(
        `${environment.baseUrl}/api/event/property`,
        body,
        httpOptions
      );

    }

    getBriefcase(){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      return this.http.get<any>(
        `${environment.baseUrl}/api/event/briefcase?limit=-1`,
        httpOptions
      );

    }

    getProperty(){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      return this.http.get<any>(
        `${environment.baseUrl}/api/event/property?limit=-1`,
        httpOptions
      );

    }

    getBriefcaseLimit(page = 1){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      return this.http.get<any>(
        `${environment.baseUrl}/api/event/briefcase?page=${page}&limit=10`,
        httpOptions
      );

    }

    getPropertyLimit(page = 1){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      return this.http.get<any>(
        `${environment.baseUrl}/api/event/property?page=${page}&limit=10`,
        httpOptions
      );

    }

    searchBriefcase(keyword){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      return this.http.get<any>(
        `${environment.baseUrl}/api/event/briefcase?keyword=${keyword}`,
        httpOptions
      );

    }

    searchProperty(keyword){

      var token = this.authService.getTokenOnly() ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };

      return this.http.get<any>(
        `${environment.baseUrl}/api/event/property?keyword=${keyword}`,
        httpOptions
      );

    }

}
