import { BrosurModel } from "./brosur.model";
import { Deserializable } from "./deserializable.model";
import { SolusiModel } from "./solusi.model";

export class RetailerProductModel implements Deserializable{

	private id:string
    private background_image:string
    private content_link:string
    private description:string
    private price:string
    private product_image_full:string
    private product_name:string
    private media_full:string
    private media:string
    private media_name:string
    private image_thumb_full:string
    private pc_x:number
    private pc_y:number
    private mobile_x:number
    private mobile_y:number
    private isShow:boolean = false

	deserialize(input: any) {
		Object.assign(this, input);

		return this;
	}

	getID(){
		return this.id
	}

    getImageUrl(){
        if(this.product_image_full){
            return this.product_image_full
        }

        if(this.media_full){
            return this.media_full
        }
		
    }

	getBackgroundUrl(){
		return this.background_image
	}

    getName(){
        if(this.product_name){
            return this.product_name
        }

        if(this.media){
            return this.media
        }

        if(this.media_name){
            return this.media_name
        }
        
    }

    getProductUrl(){
        return this.content_link
    }

    getDescription(){
        return this.description
    }

    getPrice(){
        if(this.price){
            return this.price
        }else{
            return 0
        }
        
    }

    getDesktopX(){
        return this.pc_x
    }

    getDesktopY(){
        return this.pc_y
    }

    getMobileX(){
        return this.mobile_x
    }

    getMobileY(){
        return this.mobile_y
    }

    getIsShow(){
        return this.isShow
    }

    setIsshow(status){
        this.isShow = status
    }

}