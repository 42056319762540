import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { CountdownGuard } from './services/countdown.guard';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [CountdownGuard]

  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'registration/:eventId/:code',
    loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'login',
      loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule),
      canActivate: [CountdownGuard]
  },
   {
    path: 'login/:loginCode',
      loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule),
      canActivate: [CountdownGuard]
  },
  {
    path: 'login/:loginCode/:redirect',
      loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule),
      canActivate: [CountdownGuard]
  },
  {
    path: 'terms-service',
    loadChildren: () => import('./account/sk/sk.module').then( m => m.SkPageModule),
        canActivate: [CountdownGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./account/pp/pp.module').then( m => m.PpPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'forget',
    loadChildren: () => import('./account/forget/forget.module').then( m => m.ForgetPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'forget-success',
    loadChildren: () => import('./account/forget-success/forget-success.module').then( m => m.ForgetSuccessPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'pesan',
    loadChildren: () => import('./profile/inbox/inbox.module').then( m => m.InboxPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuard],
    canActivate: [CountdownGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./profile/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard],
    canActivate: [CountdownGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./profile/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    canLoad: [AuthGuard],
    canActivate: [CountdownGuard]
  },
  {
    path: 'information',
    loadChildren: () => import('./event/information/information.module').then( m => m.InformationPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'briefcase',
    loadChildren: () => import('./event/briefcase/briefcase.module').then( m => m.BriefcasePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'survey',
    loadChildren: () => import('./event/survey/survey.module').then( m => m.SurveyPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'survey-detail/:eventId/:surveyGroupId',
    loadChildren: () => import('./event/survey-detail/survey-detail.module').then( m => m.SurveyDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'hof',
    loadChildren: () => import('./event/hof/hof.module').then( m => m.HofPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'game-center',
    loadChildren: () => import('./event/game-center/game-center.module').then( m => m.GameCenterPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'conference',
    loadChildren: () => import('./event/conference/conference.module').then( m => m.ConferencePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'kpr/home',
    loadChildren: () => import('./event/kpr/home/home.module').then( m => m.HomePageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs',
    loadChildren: () => import('./event/kpr/booth/booth.module').then( m => m.BoothPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs/project',
    loadChildren: () => import('./event/kpr/project/project.module').then( m => m.ProjectPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs/alamat',
    loadChildren: () => import('./event/kpr/alamat/alamat.module').then( m => m.AlamatPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs/alamat/:brokerSlugs/property',
    loadChildren: () => import('./event/kpr/propertylist/propertylist.module').then( m => m.PropertylistPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs/:propertySlugs',
    loadChildren: () => import('./event/kpr/detail/detail.module').then( m => m.DetailPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs/:propertySlugs/brochure',
    loadChildren: () => import('./event/kpr/brochure/brochure.module').then( m => m.BrochurePageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/booth/:developerSlugs/:propertySlugs/pricelist',
    loadChildren: () => import('./event/kpr/pricelist/pricelist.module').then( m => m.PricelistPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/developer',
    loadChildren: () => import('./event/kpr/developerlist/developerlist.module').then( m => m.DeveloperlistPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/broker',
    loadChildren: () => import('./event/kpr/brokerlist/brokerlist.module').then( m => m.BrokerlistPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'kpr/livechat',
    loadChildren: () => import('./event/kpr/livechat/livechat.module').then( m => m.LivechatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kkb/home',
    loadChildren: () => import('./event/kkb/home/home.module').then( m => m.HomePageModule),
     canActivate: [CountdownGuard]
  },
  {
    path: 'kkb/booth/:brandSlug/:productCategorySlug',
    loadChildren: () => import('./event/kkb/booth/booth-list/booth-list.module').then( m => m.BoothListPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'kkb/booth/:brandSlug/:productCategorySlug/brochure',
    loadChildren: () => import('./event/kkb/booth/brochure/brochure.module').then( m => m.BrochurePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'kkb/booth/:brandSlug/:productCategorySlug/livechat',
    loadChildren: () => import('./event/kkb/livechat/livechat.module').then( m => m.LivechatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kkb/booth/:brandSlug/:productCategorySlug/promo',
   loadChildren: () => import('./event/kkb/booth/promo/promo.module').then( m => m.PromoPageModule),
   canActivate: [CountdownGuard]
  },
      {
    path: 'kkb/booth/:brandSlug/:productCategorySlug/virtual-mall',
   loadChildren: () => import('./event/kkb/booth/virtual-mall/virtual-mall.module').then( m => m.VirtualMallPageModule),
   canActivate: [CountdownGuard]
  },
    {
    path: 'kkb/booth/:brandSlug/:productCategorySlug/virtual-promo',
    loadChildren: () => import('./event/kkb/booth/virtual-promo/virtual-promo.module').then( m => m.VirtualPromoPageModule), 
     canActivate: [CountdownGuard]
  },

  {
    path: 'kkb/livechat',
    loadChildren: () => import('./event/kkb/livechat/livechat.module').then( m => m.LivechatPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'kkb/product',
    loadChildren: () => import('./event/kkb/booth/product/product.module').then( m => m.ProductPageModule),  
    canActivate: [CountdownGuard]
  },
  {
    path: 'kkb/hubungi',
    loadChildren: () => import('./event/kkb/hubungi/hubungi.module').then( m => m.HubungiPageModule),  
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/home',
    loadChildren: () => import('./event/solusi/home/home.module').then( m => m.HomePageModule),  
    canActivate: [CountdownGuard],
    // canLoad: [AuthGuard]
  },
  {
    path: 'modal-produk',
    loadChildren: () => import('./event/solusi/modal-produk/modal-produk.module').then( m => m.ModalProdukPageModule),  
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/booth/:brandSlug/e-magazine',
    loadChildren: () => import('./event/solusi/booth/e-magazine/e-magazine.module').then( m => m.EMagazinePageModule), 
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/booth/:brandSlug/livechat',
    loadChildren: () => import('./event/solusi/booth/livechat/livechat.module').then( m => m.LivechatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'solusi/booth/:brandSlug/:productCategorySlug',
    loadChildren: () => import('./event/solusi/booth/booth.module').then( m => m.BoothPageModule),  
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/booth/:brandSlug',
    loadChildren: () => import('./event/solusi/booth/booth.module').then( m => m.BoothPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'area',
    loadChildren: () => import('./devisi/devisi.module').then( m => m.DevisiPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/booth/:brandSlug/:productCategorySlug/product',
    loadChildren: () => import('./event/solusi/product/product.module').then( m => m.ProductPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/brochure/:productCategorySlug/:productId',
    loadChildren: () => import('./event/solusi/brochure/brochure.module').then( m => m.BrochurePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/:brandSlug/:productID/brochure',
    loadChildren: () => import('./event/solusi/brochure/brochure.module').then( m => m.BrochurePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/product-detail',
    loadChildren: () => import('./event/solusi/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
      canActivate: [CountdownGuard]
  },
  {
    path: 'solusi/:brandSlug/product',
    loadChildren: () => import('./event/solusi/modal-produk/modal-produk.module').then( m => m.ModalProdukPageModule),
      canActivate: [CountdownGuard]
  },
  {
    path: 'ksm/home',
    loadChildren: () => import('./event/ksm/home/home.module').then( m => m.HomePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'ksm/booth/:brandSlug',
    loadChildren: () => import('./event/ksm/booth/booth.module').then( m => m.BoothPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'ksm/booth/:brandSlug/livechat',
    loadChildren: () => import('./event/ksm/livechat/livechat.module').then( m => m.LivechatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'ksm/booth/:brandSlug/promo',
    loadChildren: () => import('./event/ksm/promo/promo.module').then( m => m.PromoPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'ksm/booth/:brandSlug/product-booth',
    loadChildren: () => import('./event/ksm/product-booth/product-booth.module').then( m => m.ProductBoothPageModule),
    canActivate: [CountdownGuard]
    },
  {
    path: 'ksm/booth/:brandSlug/produk',
    loadChildren: () => import('./event/ksm/produk/produk.module').then( m => m.ProdukPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'ksm/booth/:brandSlug/brochure',
    loadChildren: () => import('./event/ksm/brochure/brochure.module').then( m => m.BrochurePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'ksm/livechat',
    loadChildren: () => import('./event/ksm/livechat/livechat.module').then( m => m.LivechatPageModule),
     canLoad: [AuthGuard]
  },
  // {
  //   path: 'ksm/promo',
  //   loadChildren: () => import('./event/ksm/promo/promo.module').then( m => m.PromoPageModule)
  // },
  {
    path: 'ksm/brochure',
    loadChildren: () => import('./event/ksm/brochure/brochure.module').then( m => m.BrochurePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'article',
    loadChildren: () => import('./article/article.module').then( m => m.ArticlePageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'detail-article/:articleSlug',
    loadChildren: () => import('./article/detail/detail.module').then( m => m.DetailPageModule),
    // canLoad: [AuthGuard]
  }, 
  {
    path: 'webview',
    loadChildren: () => import('./event/webview/webview.module').then( m => m.WebviewPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'kpr/hubungi',
    loadChildren: () => import('./event/kpr/hubungi/hubungi.module').then( m => m.HubungiPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/popup-info',
    loadChildren: () => import('./event/kpr/popup-info/popup-info.module').then( m => m.PopupInfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/opsi-kpr',
    loadChildren: () => import('./event/kpr/opsi-kpr/opsi-kpr.module').then( m => m.OpsiKprPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/pembelian-developer',
    loadChildren: () => import('./event/kpr/pembelian-developer/pembelian-developer.module').then( m => m.PembelianDeveloperPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/pembelian-broker',
    loadChildren: () => import('./event/kpr/pembelian-broker/pembelian-broker.module').then( m => m.PembelianBrokerPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/pembelian-secondary',
    loadChildren: () => import('./event/kpr/pembelian-secondary/pembelian-secondary.module').then( m => m.PembelianSecondaryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/pembelian-refinancing',
    loadChildren: () => import('./event/kpr/pembelian-refinancing/pembelian-refinancing.module').then( m => m.PembelianRefinancingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kpr/gallery',
    loadChildren: () => import('./event/kpr/gallery/gallery.module').then( m => m.GalleryPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'hubungi',
    loadChildren: () => import('./event/ksm/hubungi/hubungi.module').then( m => m.HubungiPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'livechat',
    loadChildren: () => import('./event/livechat/livechat.module').then( m => m.LivechatPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'search-result',
    loadChildren: () => import('./event/search-result/search-result.module').then( m => m.SearchResultPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'modal-badge',
    loadChildren: () => import('./event/game-center/modal-badge/modal-badge.module').then( m => m.ModalBadgePageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'popup-regis',
    loadChildren: () => import('./account/popup-regis/popup-regis.module').then( m => m.PopupRegisPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'livechatkpr',
    loadChildren: () => import('./event/kpr/livechat/livechat.module').then( m => m.LivechatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modal-hadiah',
    loadChildren: () => import('./modal-hadiah/modal-hadiah.module').then( m => m.ModalHadiahPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'modal-sakuku',
    loadChildren: () => import('./modal-sakuku/modal-sakuku.module').then( m => m.ModalSakukuPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'modal-flazz',
    loadChildren: () => import('./modal-flazz/modal-flazz.module').then( m => m.ModalFlazzPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'session',
    loadChildren: () => import('./event/conference/session/session.module').then( m => m.SessionPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-pesan',
    loadChildren: () => import('./profile/detail-pesan/detail-pesan.module').then( m => m.DetailPesanPageModule),
    canActivate: [CountdownGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-notifikasi',
    loadChildren: () => import('./profile/detail-notifikasi/detail-notifikasi.module').then( m => m.DetailNotifikasiPageModule),
    canActivate: [CountdownGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'pilih-kendaraan',
    loadChildren: () => import('./event/kkb/pilih-kendaraan/pilih-kendaraan.module').then( m => m.PilihKendaraanPageModule),
    canActivate: [CountdownGuard]
  },
  {
    path: 'countdown',
    loadChildren: () => import('./event/countdown/countdown.module').then( m => m.CountdownPageModule)
  },
  {
    path: 'sharebooth',
    loadChildren: () => import('./sharebooth/sharebooth.module').then( m => m.ShareboothPageModule)
  },
  {
    path: 'sk-register',
    loadChildren: () => import('./account/sk-register/sk-register.module').then( m => m.SkRegisterPageModule)
  },
  {
    path: 'openingceremony',
    loadChildren: () => import('./openingceremony/openingceremony.module').then( m => m.OpeningceremonyPageModule)
  },
  // {
  //   path: 'search-popup',
  //   loadChildren: () => import('./event/kpr/search-popup/search-popup.module').then( m => m.SearchPopupPageModule)
  // },
  {
    path: 'booth-poster',
    loadChildren: () => import('./event/booth-poster/booth-poster.module').then( m => m.BoothPosterPageModule)
  },
  {
    path: 'retailer/home',
    loadChildren: () => import('./event/retailer/home/home.module').then( m => m.HomePageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'survey-popup',
    loadChildren: () => import('./event/survey-popup/survey-popup.module').then( m => m.SurveyPopupPageModule)
  },
  {
    path: 'syarat-dan-ketentuan',
    loadChildren: () => import('./account/sk/sk.module').then( m => m.SkPageModule)
  },
  {
    path: 'kebijakan-privasi',
    loadChildren: () => import('./account/pp/pp.module').then( m => m.PpPageModule)
  },
  {
    path: 'retailer/:retailerSlug',
    loadChildren: () => import('./event/retailer/detail/detail.module').then( m => m.DetailPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'retailer/gallery',
    loadChildren: () => import('./event/retailer/gallery/gallery.module').then( m => m.GalleryRetailerPageModule),
    // canLoad: [AuthGuard]
  },  
  {
    path: 'solusi/:brandSlug/product/:productCategorySlug',
    loadChildren: () => import('./event/solusi/detail/detail.module').then( m => m.DetailPageModule),
    // canLoad: [AuthGuard]
  },
  // {
  //   path: 'solusi/gallery',
  //   loadChildren: () => import('./event/solusi/gallery/gallery.module').then( m => m.GallerySolusiPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'retailer/:retailerSlug/brochure',
    loadChildren: () => import('./event/retailer/brochure/brochure.module').then( m => m.BrochurePageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'hasil-pencarian',
    loadChildren: () => import('./event/kpr/hasil-pencarian/hasil-pencarian.module').then( m => m.HasilPencarianPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./account/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'modal-iframe-livechat',
    loadChildren: () => import('./modal-iframe-livechat/modal-iframe-livechat.module').then( m => m.ModalIframeLivechatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sk-modal',
    loadChildren: () => import('./account/sk-modal/sk-modal.module').then( m => m.SkModalPageModule)
  },
  {
    path: 'kpr/tanya-developer',
    loadChildren: () => import('./event/kpr/tanya-developer/tanya-developer.module').then( m => m.TanyaDeveloperPageModule)
  },
  {
    path: 'home-closing',
    loadChildren: () => import('./home-closing/home-closing.module').then( m => m.HomeClosingPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
