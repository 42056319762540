import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AccountService } from './services/account.service';
import { Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private accountService: AccountService,
    private authService: AuthService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    localStorage.setItem('flagpopupkprsinfo', "0");

    // this.accountService.getMemberProfile().subscribe(res => {
    //   if (res.ok == 1) {
    
    //   }else{
    //     Plugins.Storage.clear()
    //     this.router.navigateByUrl("/home");
    //   }
    // });

    return this.authService.userIsAuthenticated.pipe(
      take(1),
      switchMap(isAuthenticated => {
        if(!isAuthenticated) {
          return this.authService.autoLogin();
        } else {
          return of(isAuthenticated);
        }
      }),
      tap(isAuthenticated => {
        this.accountService.getMemberProfile().subscribe(res => {
          if (res.ok == 1) {
        
          }else{
            Plugins.Storage.clear()
            this.router.navigateByUrl("/home");
          }
        });
      })
    );

  }
}
