import { BrosurModel } from "./brosur.model";
import { Deserializable } from "./deserializable.model";
import { MediaModel } from "./media.model";

export class SolusiModel implements Deserializable{

	private id:string
    private brand_logo_full:string
    private brand_name:string
	private brand_short_description_id:string
	private brand_description_id:string
	private office_name:string
	private brand_slug:string
    private media:MediaModel[]
	private mediaImage:MediaModel[] = []
	private mediaVideo:MediaModel[] = []
	private brochure:BrosurModel[] = []
	private brandProductImageUrl:string = ""
	private brandProductImageUrl2:string = ""
	private brandUrl:string = ""
	private brand_product_category:SolusiBrandProductCategoryModel[] = []
	private brand_product:SolusiBrandProductModel[] = []
	private brandProductDetail:SolusiBrandProductModel
	private brandProductCategoryDetail: SolusiBrandProductCategoryModel
	private brand_email:any = ''

	deserialize(input: any) {
		Object.assign(this, input);

        if(input != "{}"){

            if(input.dataMedia){
                
                this.media = input.dataMedia.map((medialist:MediaModel) => new MediaModel().deserialize(medialist))

            }else{

                this.media = []

            }

			if(input.brochure){

				this.brochure = input.brochure.map((brochurelist:BrosurModel) => new BrosurModel().deserialize(brochurelist))

			}else{

				this.brochure = []

			}

			if(input.brand_product){

				this.brand_product = input.brand_product.map((list:SolusiBrandProductModel) => new SolusiBrandProductModel().deserialize(list))

			}else{

				this.brand_product = []

			}

			if(input.brand_product_category){

				this.brand_product_category = input.brand_product_category.map((list:SolusiBrandProductCategoryModel) => new SolusiBrandProductCategoryModel().deserialize(list))

			}else{

				this.brand_product_category = []

			}

			if(input.brand_product_category){

				if(input.brand_product_category.length > 0){
					this.brandProductImageUrl = input.brand_product_category[0].image_thumb_full
					if(input.brand_product_category[1]){
						this.brandProductImageUrl2 = input.brand_product_category[1].image_thumb_full
					}
				}

			}

			if(input.dataLink){
				if(input.dataLink.length > 0){
					this.brandUrl = input.dataLink[0].brand_link
				}
			}


        }else{
            this.media = []
			this.brochure = []

        }

		this.setMediaImage()
		this.setMediaVideo();


		return this;
	}

	getID(){
		return this.id
	}

	getSlug(){
		return this.brand_slug
	}

	getImageUrl(){
		return this.brand_logo_full
	}

    getName(){
        return this.brand_name
    }

	getDescription(){
		return this.brand_description_id
	}

	
	getShortDescription(){

		let doc = document.createElement('html');
		doc.innerHTML = this.brand_description_id;
		let div1 = doc.querySelectorAll('p');

		let firstHtml = '';
		div1.forEach((item, index) => {
			if (index <= 2) {
				firstHtml += '<p>'+item.innerHTML+'</p>';
			}
		});

		return firstHtml

	}

	getDescriptionParagraft(){

		let doc = document.createElement('html');
		doc.innerHTML = this.brand_description_id;
		let div1 = doc.querySelectorAll('p');

		return div1.length

	}

	getOfficeName(){
		return this.brand_short_description_id
	}

	setMediaImage(){

		this.media.forEach((item, index) => {

            if(item.getType() == 1){

				this.mediaImage = this.mediaImage.concat(item)

			}

        })

	}

	setMediaVideo(){

		this.media.forEach((item, index) => {
            if(item.getType() == 2){
				this.mediaVideo = this.mediaVideo.concat(item)

			}

        })

	}

	getMediaImages(){
		return this.mediaImage
	}

	getMediaVideo(){
		return this.mediaVideo
	}

	getBrosur(){
		return this.brochure
	}

	getBrandProductImage(){
		return this.brandProductImageUrl
	}

	getBrandProductImage2(){
		return this.brandProductImageUrl2
	}

	getBrandLink(){
		return this.brandUrl
	}

	getBrandProductList(){
		return this.brand_product
	}

	getBrandProductDetail(id){

		this.brand_product.forEach((brandCategory, index) => {
			if(brandCategory.getID() == id){
				this.brandProductDetail = brandCategory
			}
		})

		return this.brandProductDetail

	}

	getBrandProductCategoryImage(id){

		this.brand_product_category.forEach((brandCategory, index) => {
			if(brandCategory.getID() == id){
				this.brandProductCategoryDetail = brandCategory
			}
		})

		if(this.brandProductCategoryDetail){
			return this.brandProductCategoryDetail.getThumbnailUrl()
		}else{
			return ''
		}


	}

	getBrandEmail(){
		return this.brand_email
	}

}


export class SolusiBrandProductModel implements Deserializable{

	private id:string
	private product_category_slug:string
	private image_thumb_full:string
	private product_name:string
	private media:MediaModel[]
	private mediaImage:MediaModel[] = []
	private mediaVideo:MediaModel[] = []
	private brochure:BrosurModel[] = []
	private brandUrl:string = ""
	private productImageUrl:string = ""
	private productImageUrl2:string = ""
	private description:any = ''
	private flag_hubungi_kami:number = 0
	private hubungi_link:string = ''
	private category_id:string = ''

	deserialize(input: any) {
		Object.assign(this, input);

		if(input.dataMedia){
                    
			this.media = input.dataMedia.map((medialist:MediaModel) => new MediaModel().deserialize(medialist))

		}else{

			this.media = []

		}

		if(input.brochure){

			this.brochure = input.brochure.map((brochurelist:BrosurModel) => new BrosurModel().deserialize(brochurelist))

		}else{

			this.brochure = []

		}

		if(input.dataLink){
			if(input.dataLink.length > 0){
				this.brandUrl = input.dataLink[0].brand_link
			}
		}

		try{
			if(input.product){
				this.productImageUrl = input.product[0].image_thumb_full
				if(input.product[1]){
					this.productImageUrl2 = input.product[1].image_thumb_full
				}
			}
		}catch(e){

		}

		this.setMediaImage()
		this.setMediaVideo();

		return this;
	}

	getID(){
		return this.id
	}

	getCategoryID(){
		return this.category_id
	}

	getSlug(){
		return this.product_category_slug
	}

	getThumbnailUrl(){
		return this.image_thumb_full
	}

	getName(){
		return this.product_name
	}

	setMediaImage(){

		this.media.forEach((item, index) => {

            if(item.getType() == 1){

				this.mediaImage = this.mediaImage.concat(item)

			}

        })

	}

	setMediaVideo(){

		this.media.forEach((item, index) => {
            if(item.getType() == 2){
				this.mediaVideo = this.mediaVideo.concat(item)

			}

        })

	}

	getMediaImages(){
		return this.mediaImage
	}

	getMediaVideo(){
		return this.mediaVideo
	}

	getDescription(){
		return this.description
	}

	
	getShortDescription(){

		let doc = document.createElement('html');
		doc.innerHTML = this.description;
		let div1 = doc.querySelectorAll('p');

		let firstHtml = '';
		div1.forEach((item, index) => {
			if (index <= 2) {
				firstHtml += '<p>'+item.innerHTML+'</p>';
			}
		});

		return firstHtml

	}

	getDescriptionParagraft(){

		let doc = document.createElement('html');
		doc.innerHTML = this.description;
		let div1 = doc.querySelectorAll('p');

		return div1.length

	}

	getBrosur(){
		return this.brochure
	}

	getBrandLink(){
		return this.brandUrl
	}

	getProductImage(){
		return this.productImageUrl
	}

	getProductImage2(){
		return this.productImageUrl2
	}

	getFlagHubungiKami(){
		return this.flag_hubungi_kami
	}
	getHubungiKamiLInk(){
		return this.hubungi_link
	}

}

export class SolusiBrandProductCategoryModel implements Deserializable{

	private id:string
	private product_category_slug:string
	private image_thumb_full:string

	deserialize(input: any) {
		Object.assign(this, input);

		return this;
	}

	getID(){
		return this.id
	}

	getSlug(){
		return this.product_category_slug
	}

	getThumbnailUrl(){
		return this.image_thumb_full
	}

}