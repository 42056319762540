import { Deserializable } from "./deserializable.model";

export class BrosurModel implements Deserializable{

	private id:string
    private kpr_id:string
    private brochure_name:string
    private file_full:string 
    private file_download:string
    private image_thumb_full:string
    private is_saved:boolean = false

	deserialize(input: any, type:string = "other") {
		Object.assign(this, input);

        if(type == 'kpr'){

            this.image_thumb_full = input.thumb_full
            this.brochure_name = input.name

        }

		return this;
	}

	getID(){
        
        if(this.id){
            return this.id
        }

        if(this.kpr_id){
            return this.kpr_id
        }

	}

    getthumbnailUrl(){
        return this.image_thumb_full
    }

    getOpenUrl(){
        return this.file_full
    }

    getDownloadUrl(){
        return this.file_download
    }

    getName(){
        return this.brochure_name
    }

    setSaved(data){
        this.is_saved = data
    }

    isSaved(){
        return this.is_saved
    }

}