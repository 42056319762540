import { DomSanitizer } from "@angular/platform-browser";
import { Deserializable } from "./deserializable.model";

export class MediaModel implements Deserializable{

	private id:string
    private type:number
    private product_thumbnail_full:string
    private media_full:string
    private youtube_link:string
    private name:string
    private image_full:String
    private file_full:string
    private media_name:string

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getID(){
		return this.id
	}

    getType(){
        return this.type
    }

    getthumbnailUrl(){
        return this.product_thumbnail_full
    }

    getMediaUrl(){
        
        if(this.media_full){
            return this.media_full
        }

        if(this.image_full){
            return this.image_full
        }

        if(this.file_full){
            return this.file_full
        }
        

    }

    getMediaUrlType2(){
        
        if(this.media_full){
            return "'" + this.media_full + "'"
        }

        if(this.image_full){
            return "'" + this.image_full + "'"
        }

        if(this.file_full){
            return "'" + this.file_full + "'"
        }
        

    }

    getYoutubeLink(){
        return this.youtube_link
    }

    getName(){
        if(this.name){
            return this.name
        }

        if(this.media_name){
            return this.media_name
        }
        
    }

}