import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import {LoadingController, ModalController} from '@ionic/angular';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {NgForm} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {EventService} from "../../../services/event.service";

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.page.html',
  styleUrls: ['./search-popup.page.scss'],
})
export class SearchPopupPage implements OnInit {

  dropdownSettingsProvinsiPop: IDropdownSettings = {};
  dropdownSettingsKotaPop: IDropdownSettings = {};
  dropdownSettingsKondisiPop: IDropdownSettings = {};
  dropdownSettingsPropertiPop: IDropdownSettings = {};
  dropdownSettingsMinHargaPop: IDropdownSettings = {};
  dropdownSettingsMaxHargaPop: IDropdownSettings = {};

  selectedProvinceIDPop: any = [{'provinsi_id': 0,'nama': 'Semua Provinsi', fixed_price:0}];
  selectedKabupatenIDPop: any = [{'kabupaten_id': 0,'provinsi_id': 0,'nama': 'Semua Kota'}];
  selectedKondisiIDPop: any;
  selectedPropertiIDPop: any;
  selectedMinHargaIDPop: any;
  selectedMaxHargaIDPop: any;

  flagSearchPop: any = 1;
  flagSearchText: any = '';
  keywordPop: any = '';
  provincePop: any = [];
  province_id_pop: any = 0;
  kotaPop: any = [];
  kabupatenId_pop: any = 0;
  kondisiPop: any = [];
  kondisi_id_pop: any = '';
  propertiPop: any = [];
  properti_id_pop: any = 0;
  hargaMinPop: any = [];
  hargaMin_id_pop: any = '';
  hargaMaxPop: any = [];
  hargaMax_id_pop: any = '';
  dataCity: any = [];
  dataKeyword: any = [];
  dataPop: any = [];
  limit = 16;
  offset = 0;
  loadMorePop = 1;
  disableButton = 0;
  searchDataPop: any = [];
  searchDevDataPop: any = [];
  triggerSearchPop: any = 0;
  listTotal = 0;

  keywordAutocompletePop = 'name';

  constructor(
    private modalController: ModalController,    
    private generalService: GeneralService,
    private eventService: EventService,
    private loadingCtrl: LoadingController,
  ) {

  }

  ngOnInit() {

    this.dropdownSettingsKotaPop = {
      idField: 'kabupaten_id',
      textField: 'nama',
      singleSelection: true,
      limitSelection: 2,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownSettingsProvinsiPop = {
      idField: 'provinsi_id',
      textField: 'nama',
      singleSelection: true,
      limitSelection: 2,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownSettingsKondisiPop = {
      idField: 'id',
      textField: 'name',
      singleSelection: true,
      limitSelection: 2,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownSettingsPropertiPop = {
      idField: 'id',
      textField: 'name',
      singleSelection: true,
      limitSelection: 2,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownSettingsMinHargaPop = {
      idField: 'id',
      textField: 'name',
      singleSelection: true,
      limitSelection: 2,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownSettingsMaxHargaPop = {
      idField: 'id',
      textField: 'name',
      singleSelection: true,
      limitSelection: 2,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.updateFlagPop(1);

    this.getData();

  }

  getData() {
    this.getListData();
    this.getProvinsi();
    this.updateKota(0);
  }

  getListData() {
    let params = '?list_only=1';
    if (this.province_id_pop) {
      params += '&provinsi=' + this.province_id_pop;
    }
    if (this.kabupatenId_pop) {
      params += params.length > 0 ? '&kabupaten=' + this.kabupatenId_pop : '?kabupaten=' + this.kabupatenId_pop;
    }

    this.generalService.getKprData(params).subscribe(res => {
      if (res.ok == 1) {
        this.kondisiPop = res.data.list.condition;
        this.propertiPop = res.data.list.property;
        this.hargaMinPop = res.data.list.min;
        this.hargaMaxPop = res.data.list.max;

        this.selectedPropertiIDPop = [this.propertiPop[0]];
        this.selectedMinHargaIDPop = [this.hargaMinPop[0]];
        this.selectedMaxHargaIDPop = [this.hargaMaxPop[0]];

      }
    });

  }

  getProvinsi() {
    this.generalService.getProvince().subscribe(res => {
      if (res.ok == 1) {

        this.provincePop = [{provinsi_id: 0, nama: "Semua Provinsi", fixed_price: 0}];
        res.data.map(x => {
          this.provincePop.push(x);
        });

        const getFilter = res.data.find((x, index) => x.provinsi_id == this.province_id_pop);
        if (getFilter) {
          this.selectedProvinceIDPop = [getFilter];
        }
        else {
          this.selectedProvinceIDPop = [{'provinsi_id': 0,'nama': 'Semua Provinsi', fixed_price:0}];
        }

      }
    });
  }

  updateKota(provinceId) {
    this.generalService.getKota(provinceId).subscribe(res => {
      if (res.ok == 1) {
        if (provinceId == 0) {
          this.kotaPop = [{kabupaten_id: 0, provinsi_id: 0, nama: "Semua Kota"}];
          res.data.map(x => {
            if (x.provinsi_id == this.province_id_pop) {
              this.kotaPop.push(x);
            }
          });
          this.dataCity = res.data;
        }
        else {
          this.kotaPop = [{kabupaten_id: 0, provinsi_id: 0, nama: "Semua Kota"}];
          res.data.map(x => {
            this.kotaPop.push(x);
          });
        }
        this.selectedKabupatenIDPop = [{'kabupaten_id': 0,'provinsi_id': 0,'nama': 'Semua Kota'}];
      }
    });
  }

  updateKota2(provinceId) {
    this.generalService.getKota(provinceId).subscribe(res => {
      if (res.ok == 1) {
        this.kotaPop = [{kabupaten_id: 0, provinsi_id: 0, nama: "Semua Kota"}];
        this.selectedKabupatenIDPop = [{kabupaten_id: 0, provinsi_id: 0, nama: "Semua Kota"}];
        this.kabupatenId_pop = 0;
        res.data.map(x => {
          this.kotaPop.push(x);
        });
      }
    });
  }

  onSelectedProvinsi(item: any){
    this.selectedProvinceIDPop = [item];
    this.province_id_pop = item.provinsi_id;
    this.kabupatenId_pop = 0;
    this.updateKota2(this.province_id_pop);
    this.selectedKabupatenIDPop = [];
  }
  onDeselectProvince(item: any){
    this.selectedProvinceIDPop = [item];
    this.province_id_pop = item.provinsi_id;
    this.kabupatenId_pop = 0;
    this.updateKota2(this.province_id_pop);
    this.selectedKabupatenIDPop = [];
  }

  onSelectedKota(item: any){
    this.selectedKabupatenIDPop = [item];
    this.kabupatenId_pop = item.kabupaten_id;
  }
  onDeselectKota(item: any){
    this.selectedKabupatenIDPop = [item];
    this.kabupatenId_pop = item.kabupaten_id;
  }

  onSelectedKondisi(item: any){
    this.selectedKondisiIDPop = [item];
    this.kondisi_id_pop = item.id;
  }
  onDeselectKondisi(item: any){
    this.selectedKondisiIDPop = [item];
    this.kondisi_id_pop = item.id;
  }

  onSelectedProperti(item: any){
    this.selectedPropertiIDPop = [item];
    this.properti_id_pop = item.id;
  }
  onDeselectProperti(item: any){
    this.selectedPropertiIDPop = [item];
    this.properti_id_pop = item.id;
  }

  onSelectedMaxHarga(item: any){
    this.selectedMaxHargaIDPop = [item];
    this.hargaMax_id_pop = item.id;
  }
  onDeselectMaxHarga(item: any){
    this.selectedMaxHargaIDPop = [item];
    this.hargaMax_id_pop = item.id;
  }

  onSelectedMinHarga(item: any){
    this.selectedMinHargaIDPop = [item];
    this.hargaMin_id_pop = item.id;
  }
  onDeselectMinHarga(item: any){
    this.selectedMinHargaIDPop = [item];
    this.hargaMin_id_pop = item.id;
  }

  updateFlagEventPop(flag, form: NgForm) {

    if (flag === 1 && flag !== this.flagSearchPop) {
      form.setValue({
        keyword_pop: ''
      });
      this.dataPop = [];
    }
    else if (flag !== this.flagSearchPop) {
      form.setValue({
        province_id_pop: 0,
        kabupaten_id_pop: 0,
        kondisi_id_pop: '',
        properti_id_pop: 0,
        hargaMin_id_pop: 0,
        hargaMax_id_pop: 0,
        keyword_pop: '',
      });
      this.dataPop = [];
    }
    this.updateFlagPop(flag);
    if (flag == 1) {
      this.getData();
    }
  }

  updateFlagPop(flag) {

    this.flagSearchPop = flag;
    if (flag == 1) {
      this.flagSearchText = 'Cari nama Proyek / Properti';
      this.keywordPop = '';
    }
    else {
      this.flagSearchText = 'Cari Developer/Agen Properti';
      this.keywordPop = '';
    }
  }

  startSearchDataPop(form: NgForm) {
    let dataSearch = {};
    if (this.flagSearchPop == 1) {
      this.keywordPop = typeof form.value.keyword_pop == 'string' ? form.value.keyword_pop : form.value.keyword_pop.name;
      this.hargaMin_id_pop = typeof form.value.hargaMin_id_pop[0] !== 'undefined' ? form.value.hargaMin_id_pop[0].id : '';
      this.hargaMax_id_pop = typeof form.value.hargaMax_id_pop[0] !== 'undefined' ? form.value.hargaMax_id_pop[0].id : '';
      // this.kondisi_id = typeof form.value.kondisi_id[0] !== 'undefined' ? form.value.kondisi_id[0].id : '';
      this.kondisi_id_pop = form.value.kondisi_id_pop;
      this.properti_id_pop = typeof form.value.properti_id_pop[0] !== 'undefined' ? form.value.properti_id_pop[0].id : '';
      this.kabupatenId_pop = typeof form.value.kabupaten_id_pop[0] !== 'undefined' ? form.value.kabupaten_id_pop[0].kabupaten_id : 0;
      this.province_id_pop = typeof form.value.province_id_pop[0] !== 'undefined' ? form.value.province_id_pop[0].provinsi_id : 0;

      dataSearch = {
        flag: this.flagSearchPop,
        keyword: this.keywordPop,
        hargaMin_id: this.hargaMin_id_pop,
        hargaMax_id: this.hargaMax_id_pop,
        kondisi_id: this.kondisi_id_pop,
        properti_id: this.properti_id_pop,
        province_id: this.province_id_pop,
        kabupaten_id: this.kabupatenId_pop
      };

      let searchquery = 'hargaMinID='+this.hargaMin_id_pop+'|hargaMaxID='+this.hargaMax_id_pop+'|kondisiID='+this.kondisi_id_pop+'|propertyID='+this.properti_id_pop+'|kabupatenID='+this.kabupatenId_pop+'|provinceID='+this.province_id_pop;

      this.gtmSearchEngagement(this.keywordPop, searchquery)

    }
    else {
      this.keywordPop = typeof form.value.keyword == 'string' ? form.value.keyword : form.value.keyword.name;

      dataSearch = {
        flag: this.flagSearchPop,
        keyword: this.keywordPop
      };

      this.gtmSearchEngagement(this.keywordPop, '')

    }

    this.offset = 0;
    this.loadMorePop = 1;
    this.disableButton = 0;
    this.searchDataPop = [];
    this.searchDevDataPop = [];
    this.getSearchData();

    this.eventService.memberSearchActivity(
        environment.memberActivity.SearchProductKPR,
        dataSearch
    ).subscribe(res => {
    });

  }

  getSearchData() {

    this.loadingCtrl.create({ keyboardClose: true })
        .then(loading => {
          loading.present();

          let params = '';
          if (this.flagSearchPop > 0) {
            params += params.length > 0 ? '&for=' + this.flagSearchPop : '?for=' + this.flagSearchPop;
          }
          if (this.keywordPop.length > 0) {
            params += params.length > 0 ? '&s=' + this.keywordPop : '?s=' + this.keywordPop;
          }
          if (this.province_id_pop > 0) {
            params += params.length > 0 ? '&provinsi=' + this.province_id_pop : '?provinsi=' + this.province_id_pop;
          }
          if (this.kabupatenId_pop > 0) {
            params += params.length > 0 ? '&kabupaten=' + this.kabupatenId_pop : '?kabupaten=' + this.kabupatenId_pop;
          }
          // console.log(this.kondisi_id_pop);
          if (this.kondisi_id_pop.length > 0) {
            params += params.length > 0 ? '&condition=' + this.kondisi_id_pop : '?condition=' + this.kondisi_id_pop;
          }
          if (parseInt(this.properti_id_pop) > 0) {
            params += params.length > 0 ? '&property=' + this.properti_id_pop : '?property=' + this.properti_id_pop;
          }
          if (parseInt(this.hargaMin_id_pop) > 0) {
            params += params.length > 0 ? '&min=' + this.hargaMin_id_pop : '?min=' + this.hargaMin_id_pop;
          }
          if (parseInt(this.hargaMax_id_pop) > 0) {
            params += params.length > 0 ? '&max=' + this.hargaMax_id_pop : '?max=' + this.hargaMax_id_pop;
          }

          params += params.length > 0 ? '&limit=' + this.limit : '?limit=' + this.limit;
          if (this.offset > 0) {
            params += params.length > 0 ? '&offset=' + this.offset : '?offset=' + this.offset;
          }

          this.disableButton = 1;
          this.generalService.getKprSearchData(params).subscribe(res => {
            this.disableButton = 0;
            loading.dismiss();
            if (res.ok == 1) {
              if (this.flagSearchPop == 1) {

                let index = 0;

                res.data.map(x => {
                  this.searchDataPop.push(x);

                  this.gtmProductImpresion(x, "Search Result", index)
                  index = index + 1;

                });
              }
              else {
                res.data.map(x => {
                  this.searchDevDataPop.push(x);
                });
              }
              this.triggerSearchPop = 1;

              this.listTotal = res.pagination.total;
              this.limit = res.pagination.limit;
              this.offset = res.pagination.offset;
              if (res.pagination.page >= res.pagination.page_total) {
                this.loadMorePop = 0;
              }
              else {
                this.loadMorePop = 1;
              }
            }
          });

        });

  }

  getLoadMore() {
    this.getSearchData();
  }

  selectEventPop(item) {
    // do something with selected item

  }

  checkKeyPop(event, form: NgForm) {
    if (event.keyCode == 13 && this.flagSearchPop == 1) {
      this.keywordPop = typeof form.value.keyword_pop == 'string' ? form.value.keyword_pop : form.value.keyword_pop.name;
      this.hargaMin_id_pop = typeof form.value.hargaMin_id_pop[0] !== 'undefined' ? form.value.hargaMin_id_pop[0].id : '';
      this.hargaMax_id_pop = typeof form.value.hargaMax_id_pop[0] !== 'undefined' ? form.value.hargaMax_id_pop[0].id : '';
      // this.kondisi_id = typeof form.value.kondisi_id[0] !== 'undefined' ? form.value.kondisi_id[0].id : '';
      this.kondisi_id_pop = form.value.kondisi_id_pop;
      this.properti_id_pop = typeof form.value.properti_id_pop[0] !== 'undefined' ? form.value.properti_id_pop[0].id : '';
      this.kabupatenId_pop = typeof form.value.kabupaten_id_pop[0] !== 'undefined' ? form.value.kabupaten_id_pop[0].kabupaten_id : 0;
      this.province_id_pop = typeof form.value.province_id_pop[0] !== 'undefined' ? form.value.province_id_pop[0].provinsi_id : 0;

      let dataSearch = {
        flag: this.flagSearchPop,
        keyword: this.keywordPop,
        hargaMin_id: this.hargaMin_id_pop,
        hargaMax_id: this.hargaMax_id_pop,
        kondisi_id: this.kondisi_id_pop,
        properti_id: this.properti_id_pop,
        province_id: this.province_id_pop,
        kabupaten_id: this.kabupatenId_pop
      };

      this.offset = 0;
      this.loadMorePop = 1;
      this.disableButton = 0;
      this.searchDataPop = [];
      this.searchDevDataPop = [];
      this.getSearchData();

      this.eventService.memberSearchActivity(
          environment.memberActivity.SearchProductKPR,
          dataSearch
      ).subscribe(res => {
      });

    }
  }


  onChangeSearchPop(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.

    let params = '?for=' + this.flagSearchPop + '&s=' + val;

    if (this.flagSearchPop == 1) {
      if (parseInt(this.province_id_pop) > 0) {
        params += '&provinsi=' + this.province_id_pop;
      }
      if (parseInt(this.province_id_pop) > 0) {
        params += '&kabupaten=' + this.kabupatenId_pop;
      }
      if (this.kondisi_id_pop.length > 0) {
        params += '&condition=' + this.kondisi_id_pop;
      }
      if (parseInt(this.properti_id_pop) > 0) {
        params += '&property=' + this.properti_id_pop;
      }
      if (this.hargaMin_id_pop > 0) {
        params += '&min=' + this.kabupatenId_pop;
      }
      if (this.hargaMax_id_pop > 0) {
        params += '&max=' + this.hargaMax_id_pop;
      }
    }

    this.dataPop = [];
    if (val.length > 0) {
      this.generalService.getKprSearchData(params).subscribe(res => {
        setTimeout(() => {

          this.dataPop = [];
          if (this.flagSearchPop == 1) {
            res.data.map(x => {
              this.dataPop.push({
                brand_slug: x.kpr_house_slugs,
                id: x.kpr_house_id,
                jenisData: 'property',
                name: x.kpr_house_name,
                type: 1
              });
            });
          }
          else {
            res.data.map(x => {
              this.dataPop.push({
                brand_slug: x.kpr_slugs,
                id: x.kpr_id,
                jenisData: 'developer',
                name: x.kpr_name,
                type: 2
              });
            });
          }

          // console.log(this.data);

        }, 500);

      });
    }


    // this.generalService.getSearchByKeyword(val).subscribe(res => {
    //
    // //     console.log("SEarch Result : " + JSON.stringify(res))
    //     this.data = res.data;
    //   })

  }

  async closeModal() {
    await this.modalController.dismiss();
  }
  analyticClickAction(tag){
    this.generalService.sendAnalytic('click', tag).subscribe(res => {
    });
  }
  gtmSearchEngagement(keyword, detail){

    var dataAnalyticJoin = {
      'search_query': keyword,
      'search_category': 'KPR',
      'page_url': window.location.href,
      'search_detail': detail };
    this.generalService.sendTrackingGA('searchengagement',dataAnalyticJoin);


  }

  gtmProductImpresion(detail, from, index){

    var dataAnalyticJoin = {'product_name': detail.kpr_house_name ,'product_id': detail.kpr_house_id ,'product_category':'Property','product_brand': detail.parent_name ,'product_location': detail.kabupaten_name ,'product_price': detail.kpr_house_start_price +' - ' + detail.kpr_house_end_price ,'product_list': from, 'product_position' : 'slot ' + index + 1};
    this.generalService.sendTrackingGA('productdetailimpression',dataAnalyticJoin);


  }

  gtmProductClick(detail, from, index){

        var dataAnalyticJoin = {'product_name': detail.kpr_house_name ,'product_id': detail.kpr_house_id ,'product_category':'Property','product_brand': detail.parent_name ,'product_location': detail.kabupaten_name ,'product_price': detail.kpr_house_start_price +' - ' + detail.kpr_house_end_price ,'product_list': from, 'product_position' : 'slot ' + index + 1};
        this.generalService.sendTrackingGA('productclick',dataAnalyticJoin);
        
    
  }

}
